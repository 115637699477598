<template>
  <v-container fluid class="px-5">
    <DashConfigDialog
      v-if="showDashTypeDialog"
      v-model="showDashTypeDialog"
      :dash="newDash"
      @created="dashCreated"
    />
    <DashTypesDialog
      v-if="showDashTypesDialog"
      v-model="showDashTypesDialog"
      @created="dashCreated"
			@close="closeDashTypesDialog"
    />
    <v-tabs-items
      touchless
      class="transparent"
      v-model="selectedAppBarTab"
    >
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <DashCollection
          v-if="dashCollection !== null && tab.key !== 'add' "
          :collection="dashCollectionByUUID(tab.key)"
        />
        <AddDashCollectionDialog
          v-else-if="showAddDashCollection === true"
          v-on="$listeners"
          @changeTabs="changeTabs"
          @newTab="newTab"
        />
        <DashCollectionLoading v-else />
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-if="showTermsAndConditions"
      v-model="showTermsAndConditions"
      @click:outside="closeTermsAndConditions"
      max-width="800"
    >
      <TermsAndConditions :closeCallback="closeTermsAndConditions" />
    </v-dialog>
  </v-container>
</template>

<script>
import DashCollection from '@/components/dash/DashCollection'
import DashCollectionLoading from '@/components/dash/DashCollectionLoading'
import DashTypesDialog from '@/components/dash/DashTypesDialog'
import DashConfigDialog from '@/components/dash/DashConfigDialog'
import AddDashCollectionDialog from '@/components/dash/AddDashCollectionDialog'
import TermsAndConditions from '@/components/terms/TermsAndConditions'

export default {
  name: 'DashBoard',
  components: {
    DashCollection,
    DashCollectionLoading,
    DashTypesDialog,
    DashConfigDialog,
    AddDashCollectionDialog,
    TermsAndConditions
  },
  data: () => ({
    showDashTypesDialog: false,
    showDashTypeDialog: false,
    newDash: null,
    showAddDashCollection: false
  }),
  computed: {
    selectedAppBarTab() {
      return this.$store.state.selectedAppBarTab
    },    
    tabs() {
      const tabs = []

      if(this.dashCollections !== null) {
        for(let i in this.dashCollections) {
          const collection = this.dashCollections[i]
          tabs.push({
            title: collection.name,
            key: collection.uuid
          })
        }
      }
      tabs.push({title: this.$t('add'), key: "add"})
      for(let i = 0; i < tabs.length; i++) {
      }
      return tabs
    },
    dashCollection: {
      get: function() {
        return this.$store.state.dashCollection
      },
      set: function(dashCollection) {
        this.$store.commit('setDashCollection', dashCollection)
      }
    },
    dashCollections() {
      return this.$store.state.dashCollections
    },
    showTermsAndConditions() {
      return this.$store.state.showTermsAndConditions
    },
  },
  methods: {
    changeTabs() {
      this.showAddDashCollection = false
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      },
    newTab() {
      this.showAddDashCollection = false
      this.$store.dispatch('getDashCollections', this.dashCollectionByUUID()).then(result => {
       if(result){
        this.$store.commit('updateAppBarTabs', this.tabs)
       }
      }).finally(() => {
        this.$store.commit('updateSelectedAppBarTab', this.tabs[this.tabs.length - 2].key)
      })
    },
    dashCollectionByUUID(uuid) {
      if(uuid && Array.isArray(this.dashCollections)) {
        return this.dashCollections.find(item => item && item.uuid === uuid)
      }
      return []
    },
    showCreateDashCollectionDialog() {
      this.showDashTypesDialog = true
    },
    dashCreated(dash) {
      this.$store.commit('dashCreated', dash)
      this.showDashTypeDialog = false
    },
    closeDashTypesDialog() {
      this.showDashTypesDialog = false
    },    
    closeTermsAndConditions() {
      this.$store.commit('setShowTermsAndConditions', false)
    },
  },
  watch: {
    selectedAppBarTab(newValue, oldValue) {
      if(newValue === 'add') {
        this.showAddDashCollection = true
      }
      this.$store.commit('setDashCollection', this.dashCollectionByUUID(newValue))
    }
  },
  mounted() {
    this.$store.commit('updateAppBarTabs', this.tabs)
    this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
  },
  created() {
      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: this.showCreateDashCollectionDialog
      })
  },
}
</script>
